<template>
  <BaseModal v-if="!smAndDown" ref="modal" width="360" height="h-[408px]" title="لیست حساب های کاربری" icon="info" fill>
    <template #element="activatorProps">
      <div class="cursor-pointer" v-bind="activatorProps">
        <BaseIcon icon="switch-account" class="ml-2 text-blue-dark-500 align-middle" />
        <span class="text-sm text-blue-dark-400 font-bold">حساب های کاربری</span>
      </div>
    </template>
    <template #body>
      <div class="p-6">
        <div v-for="company in accounts" :key="company" class="mb-4" :class="company.status ? 'cursor-pointer' : 'cursor-not-allowed'" @click="company.status ? select = company.id : undefined">
          <div class="flex justify-between">
            <div class="flex">
              <div v-if="!company.avatar_url" class="bg-blue-dark-50 rounded text-blue-dark-200 w-10 h-10 flex items-center justify-center">
                <BaseIcon icon="domain" class="" />
              </div>
              <div
                v-else
                :style="{ 'background-image': 'url(' + company.avatar_url + ')', 'background-size': '100% 100%' }"
                class="border-[1.3px] border-primary-500 w-10 h-10 rounded-lg cursor-pointer"
              >
              </div>
              <div class="flex flex-col justify-between mr-3">
                <div class="text-sm text-blue-dark-500 font-bold">
                  {{ company.title }}
                </div>
                <div class="text-xs text-blue-dark-200">
                  حقوقی
                </div>
              </div>
            </div>
            <div>
              <div v-if="!company.status" class="text-red-600 text-center text-xs">
                <BaseIcon icon="error" fill />
                <div>مسدود</div>
              </div>
              <BaseIcon v-else-if="select === company.id" icon="radio-button-checked" class="text-blue-500" />
              <BaseIcon v-else icon="radio-button-unchecked" class="text-blue-dark-400" />
            </div>
          </div>
          <div class="border-b-[1px] w-full mt-3"></div>
        </div>
      </div>
    </template>
    <template #footer="isActive">
      <div class="p-6 flex flex-row-reverse">
        <v-btn class=" text-primary-500 hover:text-white hover:bg-blue-500 border-[1.3px] border-primary-500" @click="useAccount">
          <BaseIcon icon="check-circle" class="ml-2" />
          <span class="text-sm">
            انتخاب
          </span>
        </v-btn>
        <v-btn class=" text-primary-500 hover:text-white hover:bg-blue-500 ml-6 border-[1.3px] border-primary-500 " @click="closeDialog(isActive)">
          <BaseIcon icon="arrow-forward" class="ml-2" />
          <span class="text-sm">
            بازگشت
          </span>
        </v-btn>
      </div>
    </template>
  </BaseModal>
  <div v-else>
    <div @click="showAccounts = true">
      <BaseIcon icon="switch-account" class="text-primary-500" />
      <BaseIcon icon="arrow-down" class="text-primary-500" />
    </div>
    <BaseBottomSheet v-model="showAccounts">
      <div v-for="company in accounts" :key="company" class="mb-4" :class="company.status ? 'cursor-pointer' : 'cursor-not-allowed'" @click="switchAccountHandler(company)">
        <div class="flex justify-between">
          <div class="flex">
            <div v-if="!company.avatar_url" class="bg-blue-dark-50 rounded text-blue-dark-200 w-10 h-10 flex items-center justify-center">
              <BaseIcon icon="domain" class="" />
            </div>
            <div
              v-else
              :style="{ 'background-image': 'url(' + company.avatar_url + ')', 'background-size': '100% 100%' }"
              class="border-[1.3px] border-primary-500 w-10 h-10 rounded-md cursor-pointer"
            >
            </div>
            <div class="flex flex-col justify-between mr-3">
              <div class="text-sm text-blue-dark-500 font-bold" :class="select == company.id && 'text-primary-500'">
                {{ company.title }}
              </div>
              <div class="text-xs text-blue-dark-200" :class="select == company.id && 'text-primary-500'">
                حقوقی
              </div>
            </div>
          </div>
          <div>
            <div v-if="!company.status" class="text-red-600 text-center text-xs">
              <BaseIcon icon="error" fill />
              <div>مسدود</div>
            </div>
            <BaseIcon v-else-if="select === company.id" icon="radio-button-checked" class="text-blue-500" />
            <BaseIcon v-else icon="radio-button-unchecked" class="text-blue-dark-400" />
          </div>
        </div>
        <div class="border-b-[1px] w-full mt-3"></div>
      </div>
    </BaseBottomSheet>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import BaseModal from "@/components/base/BaseModal.vue";
import { apiGetUserAccounts } from "@/services/authentication/authentication";
import { useAuthStore } from "@/stores/authStore";
import { useRouter } from 'vue-router';
import BaseBottomSheet from "@/components/base/BaseBottomSheet.vue";
import { useDisplay } from 'vuetify';

const { smAndDown } = useDisplay()
const router = useRouter()
const authStore = useAuthStore();
const accounts: any = ref([])
const select: any = ref(null)
const modal = ref()
const showAccounts = ref(false)
const getUserAccounts = async () => {
  try {
    const {data} = await apiGetUserAccounts()
    accounts.value = data.companies
    const companyId = localStorage.getItem('company')
    console.log(companyId);
    
    companyId ? select.value = Number(companyId) : select.value = authStore.userInfo.id
  } catch (error) {
    console.log(error);
  }
}
getUserAccounts()


const closeDialog = (isActive: any) => {
  isActive.value = false
}

const useAccount = () => {
  const selectedItem = accounts.value.find((item: any) => item.id === select.value)
  if (selectedItem.account_type == 'personal') {
    localStorage.setItem('company', '')
    if (modal.value) {
      modal.value.closeDialog()
    } else showAccounts.value = false
    window.open('/dashboard', '_self')
    return
  }
  localStorage.setItem('company', select.value)
  if (modal.value) {
    modal.value.closeDialog()
  } else showAccounts.value = false
  window.open('/dashboard', '_self')
}
const switchAccountHandler = (company: any) => {
  company.status ? select.value = company.id : undefined
  useAccount()
}
</script>