import type { UserAgentType } from "@/core/models/Auth";
import type { UserInfoResponse } from "@/services/authentication/authentication.types";
import { defineStore } from "pinia";

// const TOKEN_KEY = "access_token" as string;

// export const getToken = (): string | null => {
//   return window.localStorage.getItem(TOKEN_KEY);
// };

// export const saveToken = (token: string): void => {
//   if (token === "undefined" || token === undefined) return;
//   window.localStorage.setItem(TOKEN_KEY, token);
// };

// export const destroyToken = (): void => {
//   window.localStorage.removeItem(TOKEN_KEY);
// };

export const useAuthStore = defineStore("authStore", {
  state: () => ({
    token: "",
    userHasAccount: false,
    userMobile: "",
    otpExpireTime: "",
    userAgent: {} as UserAgentType,
    userInfo: {} as UserInfoResponse,
  }),

  persist: [
    {
      key: "auth",
      paths: ["userInfo", "token"],
      storage: localStorage,
    },
  ],

  getters: {
    isLoggedIn: (state) => !!state.token,
  },
  actions: {
    logout() {
      localStorage.setItem('company', '')
      this.token = "";
      this.userHasAccount = false;
      this.userMobile = "";
      this.userInfo = {} as UserInfoResponse;
    },
  },
});
