<template>
  <BaseBottomSheet v-model="dialog">
    <div>
      <div class="flex items-center justify-between pb-3 border-b">
        <div class="flex items-center gap-3">
          <div
            v-if="authStore.userInfo.avatar_url"
            :style="{ 'background-image': 'url(' + authStore.userInfo.avatar_url + ')', 'background-size': '100% 100%' }"
            class="w-[40px] h-[40px] grid place-content-center border-2 rounded-lg"
          >
          </div>
          <div v-else class="w-[40px] h-[40px] grid place-content-center border-2 border-primary-500 rounded-lg">
            <BaseIcon icon="account-circle" fill class="text-primary-500" />
          </div>
          <div class="flex flex-col gap-y-1">
            <RouterLink to="/user-account" class="flex items-center text-blue-500">
              <span class="text-sm font-bold"> {{ fullName }} </span>
              <BaseIcon icon="arrow-left" />
            </RouterLink>
            <span class="text-blue-dark-200 text-xs">
              {{ `کاربر - کد اشتراک ${authStore.userInfo.id || ''}` }}
            </span>
          </div>
        </div>
        <UserAccounts />
      </div>
      <v-list class="p-0 flex flex-col gap-y-3 mt-4">
        <v-list-item
          link
          to="/invoices"
          exact
          color="primary-dark"
          class="!flex !items-center rounded-lg !text-blue-dark-400 px-0"
          @click="dialog = false"
        >
          <template v-slot:prepend>
            <BaseIcon icon="receipt-long" :fill="$route.path === '/invoices'" />
          </template>
          <v-list-item-title class="!text-sm !font-bold">
            صورتحساب
          </v-list-item-title>
        </v-list-item>
        <v-list-item
          color="primary-dark"
          class="!flex !items-center rounded-lg !text-blue-dark-400 px-0"
          to="/wallet"
          @click="dialog = false"
        >
          <template v-slot:prepend>
            <BaseIcon icon="wallet" :fill="$route.path === '/wallet'" />
          </template>
          <v-list-item-title class="!text-sm !font-bold">
            تاریخچه کیف پول
            <span class="text-red-500"> (جدید) </span>
          </v-list-item-title>
        </v-list-item>
        <v-list-item
          v-if="smAndDown && !isPWA"
          color="primary-dark"
          class="!flex !items-center rounded-lg !text-blue-dark-400 px-0"
          @click="openAppInstaller"
        >
          <template v-slot:prepend>
            <BaseIcon icon="install-mobile" :fill="$route.path === '/wallet'" />
          </template>
          <v-list-item-title class="!text-sm !font-bold">
            وب اپلیکیشن (PWA)
          </v-list-item-title>
        </v-list-item>
        <v-list-item
          color="primary-dark"
          class="!flex !items-center rounded-lg !text-blue-dark-400 px-0"
          @click="emit('logOut')"
        >
          <template v-slot:prepend>
            <BaseIcon icon="power" />
          </template>
          <v-list-item-title class="!text-sm !font-bold">
            خروج از سیستم
          </v-list-item-title>
        </v-list-item>
      </v-list>
      <div class="border-t-[1px] border-blue-dark-50 mt-4"></div>
      <div class="text-center mt-2 text-blue-dark-200">
        <BaseIcon icon="copyright" class="align-middle" />
        <span class="text-xs mr-2"> سیمان آینده - نسخه 1.4.9</span>
      </div>
    </div>
  </BaseBottomSheet>
  <BaseBottomSheet v-model="pwaDialog" class="h-full">
    <div class="flex flex-col justify-between">
      <div class="h-[68vh]">
        <div class="mt-14">
          <img src="~@/assets/images/logo-mobile.webp" alt="logo" class="w-[94px] h-auto my-3 mx-auto " />
          <div class="text-center mt-4">
            <BaseIcon icon="install-mobile" class="text-blue-dark-400 align-middle" fill />
            <span class="text-sm text-blue-dark-500 font-bold mr-2">راهنمای نصب وب اپلیکیشن</span>
          </div>
          <div class="mt-6 border border-dashed border-blue-dark-50 rounded-lg py-4 px-3">
            <div class="flex items-center">
              <div class="relative">
                <div class="text-center bg-blue-dark-50 w-7 h-7 rounded pt-1 text-sm">
                  1
                </div>
                <div class=" absolute border border-left border-blue-dark-200 h-[25px] right-3">
                </div>
              </div>
              <div class="mr-3 text-xs">
                <div v-if="platform.ios">
                  <span>در نوار مرورگر دکمه</span>
                  <BaseIcon icon="ios-share" class="text-blue-dark-400 align-middle text-xl mx-2 mb-1" />
                  <span>را بزنید</span>
                </div>
                <div v-else>
                  <span>در منوی بالا گزینه</span>
                  <BaseIcon icon="more-vert" class="text-blue-dark-400 align-middle text-xl" />
                  <span>(بیشتر) را بزنید</span>
                </div>
              </div>
            </div>
            <div class="flex items-center mt-6">
              <div class="relative">
                <div class="text-center bg-blue-dark-50 w-7 h-7 rounded pt-1 text-sm">
                  2
                </div>
                <div class=" absolute border border-left border-blue-dark-200 h-[25px] right-3">
                </div>
              </div>
              <div class="mr-3 text-xs">
                <div v-if="platform.ios">
                  گزینه<BaseIcon icon="add" class="text-blue-dark-400 mx-2 mb-1 border-2 border-blue-dark-400 rounded w-5 h-5 text-base pb-1" />Add to home screen را انتخاب کنید.
                </div>
                <div v-else>
                  <span>
                    سپس گزینه <span class="font-bold">افزودن به صفحه اصلی</span> یا <span class="font-bold">نصب</span> را انتخاب کنید.
                  </span>
                </div>
              </div>
            </div>
            <div class="flex items-center mt-6">
              <div class="relative">
                <div class="text-center bg-blue-dark-50 w-7 h-7 rounded pt-1 text-sm">
                  3
                </div>
                <div class=" absolute border border-left border-blue-dark-200 h-[25px] right-3">
                </div>
              </div>
              <div class="mr-3 text-xs">
                <span>در مرحله بعد <span class="font-bold">افزودن</span> را انتخاب کنید.</span>
              </div>
            </div>
            <div class="flex items-center mt-6">
              <div class="relative">
                <div class="text-center bg-blue-dark-50 w-7 h-7 rounded pt-1 text-sm">
                  4
                </div>
              </div>
              <div class="mr-3 text-xs">
                <span>از این پس آیکون</span>
                <img src="~@/assets/images/logo-mobile.webp" alt="logo" width="20" class="inline-block mx-2" />
                <span>در صفحه اصلی گوشی شما دردسترس است.</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <v-btn color="secondary" block @click="pwaDialog = false">
        متوجه شدم
      </v-btn>
    </div>
  </BaseBottomSheet>
</template>

<script lang="ts" setup>
import BaseBottomSheet from "@/components/base/BaseBottomSheet.vue";
import { isPWA } from "@/core/helpers/pwa-helper";
import { useAuthStore } from "@/stores/authStore";
import { computed, onMounted, ref } from "vue";
import { useDisplay } from "vuetify";
import UserAccounts from '@/components/user/UsersAccounts.vue'
const authStore = useAuthStore();
const { smAndDown, platform } = useDisplay();
const deferredPrompt: any = ref(null)
const pwaDialog = ref()
const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },
});
window.addEventListener('beforeinstallprompt', (event) => {
  event.preventDefault();
  deferredPrompt.value = event;
});
const emit = defineEmits(["update:modelValue", "logOut"]);

const dialog = computed({
  get() {
    return props.modelValue;
  },
  set(value: boolean) {
    emit("update:modelValue", value);
  },
});

const fullName = computed(() => {
  return (authStore.userInfo.first_name || '') + " " + (authStore.userInfo.last_name || '');
});
const openAppInstaller = async () => {
  if (deferredPrompt.value) {
    deferredPrompt.value.prompt();
    const { outcome } = await deferredPrompt.value.userChoice;
    if (outcome === 'accepted') {
      console.log('User accepted the install prompt');
    } else {
      console.log('User dismissed the install prompt');
    }
    deferredPrompt.value = null;
  } else {
    pwaDialog.value = true
  }
}
</script>

<style lang="scss" scoped></style>
