<template>
  <v-menu transition="slide-x-transition" scroll-strategy="close">
    <template v-slot:activator="{ props }">
      <div
        v-bind="props"
        class="w-10 h-10 border-[1.3px] text-blue-dark-400 rounded-lg grid place-items-center cursor-pointer relative"
      >
        <div class=" absolute -top-1 -right-1">
          <span class="relative flex h-3 w-3">
            <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-blue-300 opacity-75"></span>
            <span class="relative inline-flex rounded-full h-3 w-3 bg-blue-400"></span>
          </span>
        </div>
        <BaseIcon icon="notifications" fill class=" text-blue-dark-400" />
      </div>
    </template>
    <div class="bg-white w-[376px] h-[472px] rounded-lg notification-menu flex flex-col items-center justify-center">
      <BaseIcon icon="notifications" class=" text-blue-dark-400 text-3xl" />
      <div class="text-xs mt-3">
        اعلانی برای نمایش وجود ندارد
      </div>
    </div>
  </v-menu>
</template>

<style lang="scss" scoped>
.notification-menu {
  box-shadow: 2px 16px 40px 0px rgba(176, 212, 250, 0.40);
}
</style>