<template>
  <v-dialog v-model="dialog" :width="!smAndDown ? width : '100%'" :fullscreen="smAndDown">
    <template v-slot:activator="{ props }">
      <slot name="element" v-bind="props"></slot>
    </template>

    <template v-slot:default="{ isActive }">
      <div class="flex flex-col justify-between bg-white" :class="smAndDown ? 'h-full' :'rounded-[12px]'">
        <div class="p-6 text-blue-dark-500">
          <slot name="header">
            <BaseIcon
              :icon="icon"
              :fill="fill"
              class="ml-2 align-middle"
              @click="closeWithIcon ? dialog = false : ''"
            />
            <span class="text-sm font-bold text-blue-dark-500">{{ title }}</span>
          </slot>
        </div>
        <div class="border-t-[1px] border-blue-dark-50"></div>
        <div :class="smAndDown ? 'h-[80%]' : height" class="overflow-y-auto">
          <slot name="body"></slot>
        </div>
        <div>
          <div class="border-t-[1px] border-blue-dark-50"></div>
          <slot name="footer" v-bind="isActive"></slot>
        </div>
      </div>
    </template>
  </v-dialog>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { useDisplay } from 'vuetify';
const props = defineProps(['width', 'height', 'title', 'icon', 'fill', 'closeWithIcon'])
const { smAndDown } = useDisplay();
const dialog = ref(false)
const showDialog = () => {
  dialog.value = true
}
const closeDialog = () => {
  dialog.value = false
}
defineExpose({
  showDialog,
  closeDialog
});
</script>

<style scoped>

</style>