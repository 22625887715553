import { Api, ApiWithoutToken, Method } from "../api";
import type { UserInfoResponse } from "./authentication.types";
import type { IResponse } from "@/core/models/IResponse";

export const apiRequestOtpCode = (body: any) => {
  return Api<any>(Method.POST, "/auth/check_phone_number", null, body);
};

export const apiLoginWithOtp = (body: any, userAgent: string) => {
  return ApiWithoutToken<any>(
    Method.POST,
    "/auth/verify_code_auth",
    null,
    body,
    userAgent
  );
};

export const apiRegister = (body: any, userAgent: string) => {
  return ApiWithoutToken<any>(
    Method.POST,
    "/auth/register",
    null,
    body,
    userAgent
  );
};

export const apiLoginWithPassword = (body: any, userAgent: string) => {
  return ApiWithoutToken<any>(
    Method.POST,
    "/auth/sign_in_by_pass",
    null,
    body,
    userAgent
  );
};
export const apiUpdateAvatar = (body: any) => {
  return Api<any>(Method.POST, "/user/update_avatar", null, body);
};
export const apiDeleteAvatar = () => {
  return Api<any>(Method.GET, "/user/delete_avatar", null, null);
};
export const apiUserChangePassword = (body: any) => {
  return Api<any>(Method.POST, "/user/change_pass", null, body);
};
export const apiUserUpdatMobile = (body: any) => {
  return Api<any>(Method.POST, "/user/update_mobile", null, body);
};
export const apiUserUpdatMobileVerify = (body: any) => {
  return Api<any>(Method.POST, "/user/verify_code_update_mobile", null, body);
};
export const apiUpdateUser = (body: any) => {
  return Api<any>(Method.POST, "/user/update_user", null, body);
};
export const apiUserAuthentication = (body: any) => {
  return Api<any>(Method.POST, "/user/user_authentication", null, body);
};
export const apiGetLoginGuides = () => {
  return ApiWithoutToken<any>(Method.GET, "/auth/get_login_guides");
};

export const apiGetUserInfo = () => {
  return Api<IResponse<UserInfoResponse>>(
    Method.GET,
    "/user/get_user",
    null,
    null
  );
};


// users Accounts

export const apiGetUserAccounts = () => {
  return Api<any>(Method.GET, "/user/get_companies", null, null);
};